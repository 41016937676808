import * as React from "react";

interface FieldProps {
  name: string;
  label: string;
  value?: number;
  disabled?: boolean;
  size?: string;
  onChange?: ((name: string, number: number) => void) | undefined;
}

export class Field extends React.Component<FieldProps, {}> {
  constructor(props: FieldProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  public render(): JSX.Element {
    return (
      <div className={"mb-3 col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-" + (this.props.size || "2")}>
        <div className="form-floating">
          <input type="text" className="form-control"
            id={this.props.name}
            name={this.props.name}
            value={this.formatValue(this.props.value || 0)}
            disabled={this.props.disabled}
            onChange={this.handleChange} />

          <label htmlFor={this.props.name} className="form-label">{this.props.label}</label>
        </div>
      </div>
    );
  }

  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.target.value = event.target.value.replace(/[^\d-]/g, "");
    const value = parseInt(event.target.value, 10) || 0;

    if (this.props.onChange) {
      this.props.onChange(event.target.name, value);
    }
  }

  private formatValue(value: number) {
    return "$" + value.toLocaleString("en");
  }
}
