import { FilingStatus } from "./filing_status";
import { TaxScenario } from "./tax_scenario";

/**
 * Additional Medicare Tax
 */
export function additionalMedicareTax(taxScenario: TaxScenario): number {
  return Math.round(Math.max(taxScenario.medicareWages - thresholdAmount(taxScenario), 0) * 0.009);
}

/**
 * Threshold Amounts for Additional Medicare Tax
 */
export function thresholdAmount(taxScenario: TaxScenario) {
  switch (taxScenario.filingStatus) {
    case FilingStatus.MFJ:
      return 250000;
    case FilingStatus.MFS:
      return 125000;
    default:
      return 200000;
  }
}