import { TaxScenario } from "./tax_scenario";
import { totalIncome } from "./total_income";

export function childTaxCredit(taxScenario: TaxScenario): number {
  // @todo need to be true AGI, not just total income
  const adjustedGrossIncome = totalIncome(taxScenario);

  if (adjustedGrossIncome < 400_000) {
    return 4000;
  } else if (adjustedGrossIncome < 480_000) {
    return 4000 - Math.ceil((adjustedGrossIncome - 400000) / 1_000) * 50;
  } else {
    return 0;
  }
}
