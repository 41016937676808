import React from 'react';
import './App.css';
import { Field } from './Field';
import { additionalMedicareTax } from './tax/additional_medicare_tax';
import { capitalGainsAndLosses } from './tax/capital_gains_and_losses';
import { childTaxCredit } from './tax/child_tax_credit';
import { FilingStatus } from './tax/filing_status';
import { netInvestmentIncomeTax } from './tax/net_investment_income_tax';
import { standardDeduction } from './tax/standard_deduction';
import { taxableIncome } from './tax/taxable_income';
import { taxOnAllTaxableIncome } from './tax/tax_on_all_taxable_income';
import { createTaxScenario, TaxScenario } from './tax/tax_scenario';
import { totalIncome } from './tax/total_income';

interface AppState {
  taxScenarios: TaxScenario[];
  currentScenarioIndex: number;
}

export class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      taxScenarios: [
        createTaxScenario({taxYear: 2024, filingStatus: FilingStatus.MFJ}),
        createTaxScenario({taxYear: 2023, filingStatus: FilingStatus.MFJ}),
        createTaxScenario({taxYear: 2022, filingStatus: FilingStatus.MFJ}),
        createTaxScenario({taxYear: 2021, filingStatus: FilingStatus.MFJ}),
        createTaxScenario({taxYear: 2020, filingStatus: FilingStatus.MFJ}),
        createTaxScenario({taxYear: 2019, filingStatus: FilingStatus.MFJ}),
      ],
      currentScenarioIndex: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFilingStatusChange = this.handleFilingStatusChange.bind(this);
  }

  public componentDidMount() {
    const json = localStorage.getItem('state');
    if (json) {
      this.setState(JSON.parse(json));
    }
  }

  public componentDidUpdate() {
    const json = JSON.stringify(this.state);
    localStorage.setItem('state', json);
  }

  public render() {
    const currentScenario = this.state.taxScenarios[this.state.currentScenarioIndex];

    return (
      <div className="App container">
        <ul className="nav nav-tabs my-3">
          {
            this.state.taxScenarios.map((taxScenario, i) => {
              const taxYear = taxScenario.taxYear.toString();
              const className = this.state.currentScenarioIndex === i ? "nav-link active" : "nav-link";

              return <li className="nav-item" key={taxYear}>
                <a className={className} href={taxYear} onClick={(e) => this.setCurrentScenarioIndex(i, e)}>{taxYear}</a>
              </li>              
            })
          }

          {/* <li className="nav-item">
            <a className="nav-link" href="#new">+</a>
          </li> */}
        </ul>

        <fieldset>
          <legend>Filing Status</legend>

          <div className="row">
            <div className="mb-3 col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
              <select className="form-select" id="filingStatus" name="filingStatus" value={currentScenario.filingStatus} onChange={this.handleFilingStatusChange}>
                <option disabled>{FilingStatus.Single}</option>
                <option>{FilingStatus.MFJ}</option>
                <option disabled>{FilingStatus.MFS}</option>
                <option disabled>{FilingStatus.HOH}</option>
                <option disabled>{FilingStatus.QW}</option>
              </select>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Income</legend>

          <div className="row">
            <Field name="wages" label="Wages, salaries, tips" value={currentScenario.wages} onChange={this.handleChange} />
            <Field name="medicareWages" label="Medicare wages & tips" value={currentScenario.medicareWages} onChange={this.handleChange} />
            <Field name="taxableInterest" label="Taxable interest" value={currentScenario.taxableInterest} onChange={this.handleChange} />
            <Field name="ordinaryDividends" label="Ordinary dividends" value={currentScenario.ordinaryDividends} onChange={this.handleChange} />
            <Field name="qualifiedDividends" label="Qualified dividends" value={currentScenario.qualifiedDividends} onChange={this.handleChange} />
            <Field name="otherIncome" label="Other income" value={currentScenario.otherIncome} onChange={this.handleChange} />
            <div className="w-100"></div>
            <Field name="shortTermCapitalGain" label="Net ST capital gain or (loss)" value={currentScenario.shortTermCapitalGain} onChange={this.handleChange} size="3" />
            <Field name="longTermCapitalGain" label="Net LT capital gain or (loss)" value={currentScenario.longTermCapitalGain} onChange={this.handleChange} size="3" />
            <Field name="capitalGainOrLoss" label="Capital gain or (loss)" value={capitalGainsAndLosses(currentScenario)} onChange={this.handleChange} disabled />
            <div className="w-100"></div>
            <Field name="totalIncome" label="Total income" value={totalIncome(currentScenario)} disabled />
          </div>
        </fieldset>

        <fieldset>
          <legend>Tax and Credits</legend>

          <div className="row">
            <Field name="standardDeduction" label="Standard deduction" value={standardDeduction(currentScenario)} disabled />
            <Field name="qualifiedBusinessIncomeDeduction" label="QBI deduction" value={currentScenario.qualifiedBusinessIncomeDeduction} onChange={this.handleChange} />
            <Field name="taxableIncome" label="Taxable income" value={taxableIncome(currentScenario)} disabled />
            <Field name="tax" label="Tax" value={taxOnAllTaxableIncome(currentScenario)} disabled />
            <Field name="childTaxCredit" label="Child tax credit" value={childTaxCredit(currentScenario)} disabled />
            <Field name="foreignTaxCredit" label="Foreign tax credit" value={currentScenario.foreignTaxCredit} onChange={this.handleChange} />
          </div>
        </fieldset>

        <fieldset>
          <legend>Other taxes</legend>

          <div className="row">
            <Field name="additionalMedicareTax" label="Additional medicare tax" value={additionalMedicareTax(currentScenario)} size="3" disabled />
            <Field name="netInvestmentIncomeTax" label="Net investment income tax" value={netInvestmentIncomeTax(currentScenario)} size="3" disabled />
          </div>
        </fieldset>

        <fieldset>
          <div className="row my-3 pt-3 border-top">
            <div className="col-6">
              <legend>Total tax</legend>
            </div>
            <div className="col-6">
              <h1 className="text-end">${(taxOnAllTaxableIncome(currentScenario) - childTaxCredit(currentScenario) - currentScenario.foreignTaxCredit + additionalMedicareTax(currentScenario) + netInvestmentIncomeTax(currentScenario)).toLocaleString("en")}</h1>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }

  private handleChange(name: string, value: number) {
    this.setState(state => {
      let taxScenarios = state.taxScenarios;
      taxScenarios[state.currentScenarioIndex][name as keyof Omit<TaxScenario, "filingStatus">] = value;
      return {...state, taxScenarios: taxScenarios};
    });
  }

  private handleFilingStatusChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState(state => {
      let taxScenarios = state.taxScenarios;
      taxScenarios[state.currentScenarioIndex]["filingStatus"] = event.target.value as FilingStatus;
      return {...state, taxScenarios: taxScenarios};
    });
  }

  private setCurrentScenarioIndex(index: number, event: React.SyntheticEvent) {
    event.preventDefault();
    this.setState({currentScenarioIndex: index});
  }
}
