/**
 * @todo add filingStatus
 */
export function tax(taxYear: number, taxableIncome: number): number {
  switch (taxYear) {
    case 2018:
      switch (true) {
        case (taxableIncome > 0 && taxableIncome <= 19050):       return             0.1 * taxableIncome;
        case (taxableIncome > 19050 && taxableIncome <= 77400):   return   1905.0 + 0.12 * (taxableIncome -  19050);
        case (taxableIncome > 77400 && taxableIncome <= 165000):  return   8907.0 + 0.22 * (taxableIncome -  77400);
        case (taxableIncome > 165000 && taxableIncome <= 315000): return  28179.0 + 0.24 * (taxableIncome - 165000);
        case (taxableIncome > 315000 && taxableIncome <= 400000): return  64179.0 + 0.32 * (taxableIncome - 315000);
        case (taxableIncome > 400000 && taxableIncome <= 600000): return  91379.0 + 0.35 * (taxableIncome - 400000);
        case (taxableIncome > 600000):                            return 161379.0 + 0.37 * (taxableIncome - 600000);
        default:                                                  return 0;
      }

      case 2019:
        switch (true) {
          case (taxableIncome > 0 && taxableIncome <= 19400):       return             0.1 * taxableIncome;
          case (taxableIncome > 19400 && taxableIncome <= 78950):   return   1940.0 + 0.12 * (taxableIncome -  19400);
          case (taxableIncome > 78950 && taxableIncome <= 168400):  return   9086.0 + 0.22 * (taxableIncome -  78950);
          case (taxableIncome > 168400 && taxableIncome <= 321450): return  28765.0 + 0.24 * (taxableIncome - 168400);
          case (taxableIncome > 321450 && taxableIncome <= 408200): return  65497.0 + 0.32 * (taxableIncome - 321450);
          case (taxableIncome > 408200 && taxableIncome <= 612350): return  93257.0 + 0.35 * (taxableIncome - 408200);
          case (taxableIncome > 612350):                            return 164709.5 + 0.37 * (taxableIncome - 612350);
          default:                                                  return 0;
        }
    
      case 2020:
        switch (true) {
          case (taxableIncome > 0 && taxableIncome <= 19750):       return             0.1 * taxableIncome;
          case (taxableIncome > 19750 && taxableIncome <= 80250):   return   1975.0 + 0.12 * (taxableIncome -  19750);
          case (taxableIncome > 80250 && taxableIncome <= 171050):  return   9235.0 + 0.22 * (taxableIncome -  80250);
          case (taxableIncome > 171050 && taxableIncome <= 326600): return  29211.0 + 0.24 * (taxableIncome - 171050);
          case (taxableIncome > 326600 && taxableIncome <= 414700): return  66543.0 + 0.32 * (taxableIncome - 326600);
          case (taxableIncome > 414700 && taxableIncome <= 622050): return  94735.0 + 0.35 * (taxableIncome - 414700);
          case (taxableIncome > 622050):                            return 167307.5 + 0.37 * (taxableIncome - 622050);
          default:                                                  return 0;
        }
    
      case 2021:
        switch (true) {
          case (taxableIncome > 0 && taxableIncome <= 19900):       return             0.1 * taxableIncome;
          case (taxableIncome > 19900 && taxableIncome <= 81050):   return   1990.0 + 0.12 * (taxableIncome -  19900);
          case (taxableIncome > 81050 && taxableIncome <= 172750):  return   9328.0 + 0.22 * (taxableIncome -  81050);
          case (taxableIncome > 172750 && taxableIncome <= 329850): return  29502.0 + 0.24 * (taxableIncome - 172750);
          case (taxableIncome > 329850 && taxableIncome <= 418850): return  67206.0 + 0.32 * (taxableIncome - 329850);
          case (taxableIncome > 418850 && taxableIncome <= 628300): return  95686.0 + 0.35 * (taxableIncome - 418850);
          case (taxableIncome > 628300):                            return 168993.5 + 0.37 * (taxableIncome - 628300);
          default:                                                  return 0;
        }

      case 2022:
        switch (true) {
          case (taxableIncome > 0 && taxableIncome <= 20550):       return             0.1 * taxableIncome;
          case (taxableIncome > 20550 && taxableIncome <= 83550):   return   2055.0 + 0.12 * (taxableIncome -  20550);
          case (taxableIncome > 83550 && taxableIncome <= 178150):  return   9615.0 + 0.22 * (taxableIncome -  83550);
          case (taxableIncome > 178150 && taxableIncome <= 340100): return  30427.0 + 0.24 * (taxableIncome - 178150);
          case (taxableIncome > 340100 && taxableIncome <= 431900): return  69295.0 + 0.32 * (taxableIncome - 340100);
          case (taxableIncome > 431900 && taxableIncome <= 647850): return  98671.0 + 0.35 * (taxableIncome - 431900);
          case (taxableIncome > 647850):                            return 174253.5 + 0.37 * (taxableIncome - 647850);
          default:                                                  return 0;
        }

      case 2023:
        switch (true) {
          case (taxableIncome > 0 && taxableIncome <= 22000):       return             0.1 * taxableIncome;
          case (taxableIncome > 22000 && taxableIncome <= 89450):   return   2200.0 + 0.12 * (taxableIncome -  22000);
          case (taxableIncome > 89450 && taxableIncome <= 190750):  return  10294.0 + 0.22 * (taxableIncome -  89450);
          case (taxableIncome > 190750 && taxableIncome <= 364200): return  32580.0 + 0.24 * (taxableIncome - 190750);
          case (taxableIncome > 364200 && taxableIncome <= 462500): return  74208.0 + 0.32 * (taxableIncome - 364200);
          case (taxableIncome > 462500 && taxableIncome <= 693750): return 105664.0 + 0.35 * (taxableIncome - 462500);
          case (taxableIncome > 693750):                            return 186601.5 + 0.37 * (taxableIncome - 693750);
          default:                                                  return 0;
        }

        case 2024:
          switch (true) {
            case (taxableIncome > 0 && taxableIncome <= 23200):       return             0.1 * taxableIncome;
            case (taxableIncome > 23200 && taxableIncome <= 94300):   return   2320.0 + 0.12 * (taxableIncome -  23200);
            case (taxableIncome > 94300 && taxableIncome <= 201050):  return  10852.0 + 0.22 * (taxableIncome -  94300);
            case (taxableIncome > 201050 && taxableIncome <= 383900): return  34337.0 + 0.24 * (taxableIncome - 201050);
            case (taxableIncome > 383900 && taxableIncome <= 487450): return  78221.0 + 0.32 * (taxableIncome - 383900);
            case (taxableIncome > 487450 && taxableIncome <= 731200): return 111357.0 + 0.35 * (taxableIncome - 487450);
            case (taxableIncome > 731200):                            return 196669.5 + 0.37 * (taxableIncome - 731200);
            default:                                                  return 0;
          }

    default:
      return 0;
  }
}
