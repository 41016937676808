import { TaxScenario } from "./tax_scenario";
import { tax } from '../tax';
import { taxableIncome } from './taxable_income';

export function taxOnAllTaxableIncome(taxScenario: TaxScenario): number {
  const taxableIncomeExCapital = taxableIncome(taxScenario) - taxScenario.qualifiedDividends -
    // If either line 15 or 16 is blank or loss, enter 0.
    Math.max(
      0,
      // Enter the smaller of line 15 or 16 of Schedule D.
      Math.min(
        taxScenario.longTermCapitalGain,
        taxScenario.shortTermCapitalGain + taxScenario.longTermCapitalGain
      )
    );

  return Math.round(tax(taxScenario.taxYear, taxableIncomeExCapital)) +
    taxOnCapital(taxScenario.qualifiedDividends + Math.max(0, taxScenario.longTermCapitalGain));
}

/**
 * @todo rename
 * @todo implement progressive scale
 */
function taxOnCapital(capital: number) {
  return Math.round(capital * 0.15);
}
