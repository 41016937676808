import { FilingStatus } from "./filing_status";
import { TaxScenario } from "./tax_scenario";

export function standardDeduction(taxScenario: TaxScenario): number {
  switch (taxScenario.filingStatus) {
    case FilingStatus.Single:
    case FilingStatus.MFS:
      switch (taxScenario.taxYear) {
        case (2018): return 12000;
        case (2019): return 12200;
        case (2020): return 12400;
        case (2021): return 12550;
        case (2022): return 12950;
        case (2023): return 13850;
        case (2024): return 14600;
        default:     return 0;
      }
    case FilingStatus.MFJ:
    case FilingStatus.QW:
      switch (taxScenario.taxYear) {
        case (2018): return 24000;
        case (2019): return 24400;
        case (2020): return 24800;
        case (2021): return 25100;
        case (2022): return 25900;
        case (2023): return 27700;
        case (2024): return 29200;
        default:     return 0;
      }
    case FilingStatus.HOH:
      switch (taxScenario.taxYear) {
        case (2018): return 18000;
        case (2019): return 18350;
        case (2020): return 18650;
        case (2021): return 18800;
        case (2022): return 19400;
        case (2023): return 20800;
        case (2024): return 21900;
        default:     return 0;
      }
  }
}
