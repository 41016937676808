import { capitalGainsAndLosses } from "./capital_gains_and_losses";
import { FilingStatus } from "./filing_status";
import { TaxScenario } from "./tax_scenario";
import { totalIncome } from "./total_income";

export function totalInvestmentIncome(taxScenario: TaxScenario): number {
  return taxScenario.taxableInterest + taxScenario.ordinaryDividends + capitalGainsAndLosses(taxScenario);
}

export function netInvestmentIncomeTax(taxScenario: TaxScenario): number {
  // @todo need to be true AGI, not just total income
  const adjustedGrossIncome = totalIncome(taxScenario);

  let threshold;

  switch (taxScenario.filingStatus) {
    case FilingStatus.MFJ:
    case FilingStatus.QW:
      threshold = 250000;
      break;
    case FilingStatus.MFS:
      threshold = 125000;
      break;
    default:
      threshold = 200000;
      break;
  }

  const taxableInvestmentIncome = Math.min(
    Math.max(0, totalInvestmentIncome(taxScenario)),
    Math.max(adjustedGrossIncome - threshold, 0)
  );

  return Math.round(taxableInvestmentIncome * 0.038);
}
