import { FilingStatus } from "./filing_status";

export interface TaxScenario {
  taxYear: number;
  filingStatus: FilingStatus;
  wages: number;
  medicareWages: number;
  taxableInterest: number;
  qualifiedDividends: number;
  ordinaryDividends: number;
  shortTermCapitalGain: number;
  longTermCapitalGain: number;
  otherIncome: number;
  qualifiedBusinessIncomeDeduction: number;
  foreignTaxCredit: number;
}

// taxYear and filingStatus are required, all other fields are optional (will default to 0 in the factory).
interface TaxScenarioParams extends
  Pick<TaxScenario, "taxYear" | "filingStatus">, 
  Partial<Omit<TaxScenario, "taxYear" | "filingStatus">> {};

export function createTaxScenario(taxScenario: TaxScenarioParams): TaxScenario {
  return {
    taxYear: taxScenario.taxYear,
    filingStatus: taxScenario.filingStatus,
    wages: taxScenario.wages || 0,
    medicareWages: taxScenario.medicareWages || taxScenario.wages || 0,
    taxableInterest: taxScenario.taxableInterest || 0,
    qualifiedDividends: taxScenario.qualifiedDividends || 0,
    ordinaryDividends: taxScenario.ordinaryDividends || 0,
    shortTermCapitalGain: taxScenario.shortTermCapitalGain || 0,
    longTermCapitalGain: taxScenario.longTermCapitalGain || 0,
    otherIncome: taxScenario.otherIncome || 0,
    qualifiedBusinessIncomeDeduction: taxScenario.qualifiedBusinessIncomeDeduction || 0,
    foreignTaxCredit: taxScenario.foreignTaxCredit || 0,
  }
}
